import { Injectable, Injector } from '@angular/core';
//import axiosHTTP from 'src/app/_metronic/shared/axios/axios';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { AxiosService } from 'src/app/_metronic/core/services/axios.service';

const DateTimeRegex =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?$/;

@Injectable()
export class RulesService {
  http: AxiosService;
  constructor(private injector: Injector) {
    this.http = this.injector.get<AxiosService>(AxiosService);
  }
  getData(model: string, params: any, id?) {
    return new Promise(async (solve, error) => {
      //console.log('>>>>>>', JSON.stringify(params), model, id);
      const result = await this.http
        .axiosHTTP()
        .get(`${environment.apiUrl}/${model}/${id}`, {
          params,
        });
      //console.log('RESULT YEAH', result);
      if (result.status === 200) {
        // //console.log(JSON.stringify(result.data));
        // return result.data.data;
        solve(result.data.data);
      } else {
        error(result);
      }
      // if(result){solve(result);} else{error(result)}
    });
  }

  async addData(endpoint, data: any) {
    this.dateDataChange(data.rule);
    //console.log('Add', JSON.stringify(data));
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/${endpoint}`, data)
      .catch((err) => err.response);
    Promise.all([result]);
    if (result.status === 200) {
      return result.data;
    }
    return result.data;

    return [];
  }

  async updateData(endpoint, data: any, id: number) {
    
    this.dateDataChange(data);
    //console.log('SERVICE', data, id);
    const result = await this.http
      .axiosHTTP()
      .put(`${environment.apiUrl}/${endpoint}/${id}`, data)
      .catch((err) => err.response);
    Promise.all([result]);
     if (result?.status === 200) {
      return result.data;
    }
    return result.data;

    return [];
  }

  async deleteData(endpoint, data: any, id: number) {
    const result = await this.http
      .axiosHTTP()
      .delete(`${environment.apiUrl}/${endpoint}/${id}`, {
        params: data,
      })
      .catch((err) => err.response);
    Promise.all([result]);
    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      return result.data;
    }
    return result.data;

    return [];
  }

  dateDataChange(data: any) {
    for (const [key, value] of Object.entries(data)) {
      if (
        !!value &&
        (DateTimeRegex.test(value.toString()) || value instanceof Date)
      ) {
        //console.log('Date===', value, this.formatDate(value));
        // data[key] = new Date(value.toString()).toUTCString();
        /* var m = moment(this.formatDate(value));
				data[key] = m.format(); //=> "2013-10-06T00:00:00+00:00" */
        data[key] = this.formatDate(value);
      }
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }
}
