import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

import { NavigationExtras, Router } from '@angular/router';
import { GenericService } from 'src/app/_metronic/core/services/generic.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FaceSheetService } from './face-sheet.service';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgModule,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'kt-facesheet',
  templateUrl: './face-sheet.component.html',
  styleUrls: ['./face-sheet.component.scss'],
    providers:[FaceSheetService]
})

export class FaceSheetComponent implements OnInit {
  @ViewChild('content') modalContent: TemplateRef<any>;
  @ViewChild('content2') content: ElementRef;
  
  dataSource: any[] = [];
    
  listData: any[] = [];
  constructor(//private modalService: NgbModal, 
              private dialogRef: MatDialogRef<FaceSheetComponent>,
              private service:FaceSheetService, 
              public gService: GenericService,
              private router: Router,
              private renderer: Renderer2,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cdr: ChangeDetectorRef,) {}

  async ngOnInit() {
    //console.log("chegou: ", this.data.dataSource);
    const route = this.router.url.split('/');
    const screenID = route[2]; //this.router.url.split('/').join('');
    const allow: any = await this.gService.allowRoles({ screenID: 'expenseRespHistory' });
    if (!allow.access.allow) this.router.navigateByUrl('dashboard');

   this.listData = this.data.dataSource.reduce((acc, curr) => {
  const [id_expense_sheet, id_workunit] = curr.id.split('_');
  const existing = acc.find(item => item.id_expense_sheet === id_expense_sheet);
  if (existing) {
    existing.id_workunit.push(id_workunit);
  } else {
    acc.push({ id_expense_sheet, id_workunit: [id_workunit] });
  }
  return acc;
}, []);  
  const res: any = await this.service.getHistExpenseDetails({data: this.listData});
  if(res.status === 200) {
    this.dataSource = res.data.result;
  }

  }
  closeDialog() {
    this.dialogRef.close();
  }

  
  getContabTotal(i: number, j: number, k: number, name: string): number {
    const d: any = this.dataSource[i].data[j].details[k];
    if(d.name===name) {
    return this.dataSource[i].data[j].details[k].data.reduce((total, item:any) =>
       total + item.value, 0);
    }
  }

  setPageBreak() {

    const elements = document.getElementsByClassName("month-containers");
    for (let i = 0; i < elements.length; i++) {
      const element:any = elements.item(i);
      this.renderer.setStyle(element, 'page-break-before', 'always');
    }
    this.cdr.detectChanges();
  }

  getObjectKeys(obj) {
    if(!obj) return;
    return Object.keys(obj);
  }
  getCaption(fields, field: string) {
    const item:any = fields.filter((e:any)=> e.field===field);
    if(item.length > 0) return item[0].caption;
    return "";
  }
  print(){
    this.setPageBreak();
    const printContent = this.content.nativeElement.innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Impressão</title>
          <style>
          @media print {
            
            .face-sheet {
              margin: 0 auto;
              max-width: 800px;
            }
            
            h1, h2, h3 {
              text-align: center;
            }
            
            table {
              border-collapse: collapse;
              width: 100%;
              margin-bottom: 20px;
            }
            
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            
            th {
              background-color: #f2f2f2;
            }
             
            .no-print {
              display: none;
            }
            .mat-dialog-container {
              max-height: none !important;
            }
          
            .mat-dialog-container::-webkit-scrollbar {
              display: none;
            }
            ::ng-deep .mat-dialog-container {
              overflow: visible !important;
            }
            ::ng-deep .mat-dialog-container::-webkit-scrollbar {
              display: none;
            }
            .month-containers {
              page-break-before: always;
            }
            h2 {
              font-size: 1rem;
              letter-spacing: 2px;
              column-span: all;
            }
          
            
          }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    popupWin.document.close();
    popupWin.onload = () => {
      popupWin.focus();
      popupWin.print();
      popupWin.close();
    };
  }
 
}
