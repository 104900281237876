import { Location } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { LayoutService } from '../../../../_metronic/core';
import { AsideService } from './aside.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  providers: [AsideService],
  
})

export class AsideComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  submodelCollapsed: boolean = false;
  headerLogo: string;

  brandSkin: string;

  ulCSSClasses: string;

  location: Location;

  asideMenuHTMLAttributes: any = {};

  asideMenuCSSClasses: string;

  asideMenuDropdown;

  brandClasses: string;

  asideMenuScroll = 1;

  asideSelfMinimizeToggle = true;

  items: any;
  //showMenu: any[] = [];
  menuLink: string = "'./assets/media/svg/icons/Clothes/Cap.svg";
  searchQuery: string = '';  

  constructor(
    private layout: LayoutService,
    private loc: Location,
    private service: AsideService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

 filterItems() {
    return this.items.filter(item => this.itemMatchesSearch(item));
  }
   private itemMatchesSearch(item: any): boolean {
    // Verifica se a seção ou submodel correspondente à busca
    if (item.section && item.section.toLowerCase().includes(this.searchQuery.toLowerCase())) {
      return true;
    }
    
    if (item.submodel && item.submodel.toLowerCase().includes(this.searchQuery.toLowerCase())) {
      return true;
    }

    // Verifica na lista de submodelos
    if (item.list) {
      return item.list.some(m => this.itemMatchesSearch(m));
    }

    return false;
  }
  async ngOnInit() {
    
    this.items = await this.service.getMenus();
    //console.log(this.items);
    this.items = this.items.header.items;
    this.items.map((e, index) =>{
     // this.showMenu.push("menu-item show");
      e["cssClass"] = "menu-item show";
    });

    this.cdr.detectChanges();
    // //console.log('ppp', this.items);
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown')
      ? '1'
      : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing

    this.location = this.loc;
  }
  toggleSubmodelCollapse() {
    this.submodelCollapsed = !this.submodelCollapsed;
  }
  private getLogo() {
    if (this.brandSkin === 'light') {
      return './assets/media/logos/logo-dark.png';
    }
    return './assets/media/logos/logo-light.png';
  }
  clickMenu2(value: boolean, index: number) {
      let css = "menu-item show";
      if(!value)css = "menu-item hidden";
       let found = false;
       let sector = "";
       this.items.map((e, line) => {
          if(index === line) {
             found = true;
             sector = e.section;
          }
          if(found && !e.section) e.cssClass = css;
          if(e.section && sector !== e.section) found = false;
       })

       this.items[index].cssClass = css;
       this.cdr.detectChanges();
  }
  clickMenu(value: boolean, index: number) {
    // Define a classe de exibição com base no valor do colapso
    let css = value ? "menu-item show" : "menu-item hidden";

    // Variáveis de controle para encontrar se um setor foi encontrado
    let found = false;
    let sector = "";

    // Mapeia os itens para ajustar a classe CSS
    this.items.forEach((e, line) => {
        if (index === line) {
            found = true;
            sector = e.section; // Armazena o setor encontrado
        }

        // Se um setor foi encontrado e o item não é uma seção, ajuste a classe
        if (found && !e.section) {
            e.cssClass = css; // Aplique a classe ao item
        }

        // Se encontrou uma seção e não é a seção atual, redefine a busca
        if (e.section && sector !== e.section) {
            found = false;
        }
    });

    // Aplique a classe CSS apenas ao item atual
    this.items[index].cssClass = css;

    // Chama detectChanges para garantir que o Angular reconheça a mudança
    this.cdr.detectChanges();
}
  clickSubmodel2(item: any) {
    //event.stopPropagation(); // Impede que o clique suba para o menu pai
    item.submodel2Collapsed = !item.submodel2Collapsed; // Alterna o estado do submodel2
    this.cdr.detectChanges(); // Atualiza o DOM
  }

}
