import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
  AfterContentChecked,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import 'brace';
import 'brace/theme/eclipse';
import 'brace/mode/javascript';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { DxTreeViewComponent } from 'devextreme-angular';
import { GenericService } from '../../_metronic/core/services/generic.service';
import { FrmComponent } from '../../_metronic/shared/devextreme/frm/frm.component';
import { isNotUndefined } from '../../_metronic/core/util/functions';
import { RulesService } from './rules.service';
import { DataShareService } from '../../_metronic/core/services/datashare.service';
import { AceEditorComponent } from 'ng2-ace-editor';
@Component({
  selector: 'kt-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('editor', { static: false }) editor!: AceEditorComponent;
  @ViewChildren(FrmComponent) frmComponent: QueryList<FrmComponent>;
  @Input() screenData;
  @Input() name: string = '';
  @Input() parentData;
  @Input() allRulesGridData;
  @Input() typeData: string ="css";
  @Output() popUpClose: EventEmitter<any> = new EventEmitter();
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;

  fontSize: number = 14;
  treeBoxValue: string[];
  gridDataSource: any;
  showSelect: boolean = false;
  gridBoxValue: number[] = [3];
  code = `function testThis() {
		//console.log("it's working!")
	  }`;
  id: any;
  screen: any;
  formdata: any = {};
  form: QueryList<FrmComponent>;
  disabledDates: Date[];
  private unsubscribe: Subscription[] = [];
  storeInitialData: any = {};
  formIdField: any;
  formAttempted: boolean;
  customValidationErrors: any = [];
  treeDataSource: any[] = [];
  constructor(
    private service: RulesService,
    private dataShareService: DataShareService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.treeDataSource = [
      {
        ID: 0,
        name: 'Todos',
        expanded: true,
      },

      {
        ID: '1',
        categoryId: 0,
        name: 'Normal',
        expanded: true,
      },
      {
        ID: '2',
        categoryId: 0,
        name: 'Extra',
      },
      {
        ID: '3',
        categoryId: 0,
        name: 'Natal',
      },
    ];
    //this.treeBoxValue = ["1", "3"];
    const subscr = this.dataShareService
      .getPopupCloseBehavior()
      .subscribe((close) => {
        this.discardChanges();
      });
    this.unsubscribe.push(subscr);
  }

  increaseFont() {
    this.fontSize += 2;
    this.setEditorFontSize(this.fontSize);
  }

decreaseFont() {
 if (this.fontSize > 10) { // Limite mínimo de 10px
      this.fontSize -= 2;
      this.setEditorFontSize(this.fontSize);
    }
  }

  // Método que aplica o tamanho da fonte no editor
  private setEditorFontSize(fontSize: number) {
    const aceEditor = this.editor.getEditor(); // Obtenha a instância do Ace Editor
    aceEditor.setOption('fontSize', fontSize); // Ajuste o tamanho da fonte
  }

  ngAfterViewInit() {
    this.editor &&
      this.editor.getEditor().setOptions({
        tabSize: 1,
        maxLines: Infinity, // this is going to be very slow on large documents
        useWrapMode: true, // wrap text to view
        indentedSoftWrap: false,
        behavioursEnabled: false, // disable autopairing of brackets and tags
        showLineNumbers: false, // hide the gutter
      });
  }

  ngOnChanges() {
    this.customValidationErrors = [];
    if (!!this.screenData) {
      this.id = this.screenData.id;
      this.formIdField = this.screenData.modelData.screen[0]['dataField'];
      const paramObject = this.setParams();
      this.customValidationErrors = [];
      //paramObject["id"] = this.data.id
      if (!!this.id) {
        this.service
          .getData(
            paramObject['endpoint'],
            paramObject['params'],
            this.screenData.id
          )
          .then(async (data: any) => {
            //console.log('DATAxxxsss', data);

            this.formdata = data[0];
            //console.log('DATAAAA', this.formdata);
            if (!this.formdata) {
              this.formdata = {};
              for (let element of this.screenData.modelData.screen) {
                let value = '';
                if (element['dataField'] === 'name') value = this.name;
                this.formdata[element['dataField']] = value;
              }
            }
            this.storeInitialData = Object.assign({}, this.formdata);
            this.screen = this.screenData.modelData.screen;
            this.manipulateScreenData();
            this.checkIfAbonoDesconto(data[0].type);
            ////console.log("screen", this.screen);

            this.cdr.detectChanges();
          });
      } else {
        for (let element of this.screenData.modelData.screen) {
          let value = '';
          if (element['dataField'] === 'name') value = this.name;
          this.formdata[element['dataField']] = value;
        }
        this.storeInitialData = Object.assign({}, this.formdata);
        this.screen = this.screenData.modelData.screen;
        this.manipulateScreenData();
        this.cdr.detectChanges();
      }
    }
  }
  setParams() {
    const Object = {};
    switch (this.screenData.modelData.model) {
      case 'Concept':
        var paramObject = {};
        paramObject['concept'] = this.screenData.id;
        paramObject['rule'] = this.parentData['baseCode'];
        Object['params'] = paramObject;
        Object['endpoint'] = 'rule';
        break;
      case 'Query':
        var paramObject = {};
        paramObject[this.formIdField] = this.screenData.id;
        Object['params'] = paramObject;
        Object['endpoint'] = 'queryrule';
        break;
      default:
        break;
    }
    return Object;
  }
  checkIfAbonoDesconto(type): any {
    if (type !== 'Abono' && type !== 'Desconto') return;
    for (const iterator of this.screen) {
      if (
        iterator.dataField === 'id_segsoc_code' &&
        iterator.hasOwnProperty('formVisibility')
      ) {
        //console.log(iterator);
        iterator['formVisibility'] = true;
      }
    }
  }
  manipulateScreenData(): any {
    for (const iterator of this.screen) {
      if (!iterator.hasOwnProperty('formVisibility')) {
        iterator['formVisibility'] = true;
      }
      if (iterator.dataField === 'start_date' && this.formdata['end_date']) {
        iterator['max'] = 'end_date';
      }
      if (iterator.dataField === 'end_date' && this.formdata['start_date']) {
        iterator['min'] = 'start_date';
      }
    }
  }

  getValue(e) {
    ////console.log(e)
    ////console.log(eval(this.editor.value));
  }

  discardChanges() {
    //console.log('Discard Changes');
    this.screenData = undefined;
    if (
      Object.entries(this.storeInitialData).toString() ===
      Object.entries(this.formdata).toString()
    ) {
      //console.log('data same', this.storeInitialData, this.formdata);
    } else {
      //console.log('data not same', this.storeInitialData, this.formdata);
      this.formdata = this.storeInitialData;
    }
  }

  cancelClick(e) {
    //console.log('Cancel', e);
    this.popUpClose.emit();
    //this.discardChanges();
  }
  saveClick(e) {
    this.formAttempted = true;
    //const startDate = new Date(this.formdata['start_date']);
    //this.formdata['start_date'] = new Date( startDate.getFullYear(), startDate.getMonth(), 1);
    //this.formdata['start_date']  = moment(this.formdata['start_date'] ).valueOf();
    //console.log('Save', e, this.formdata);
    if (this.formdata['end_date']) {
      const endDate = new Date(this.formdata['end_date']);
      this.formdata['end_date'] = new Date(
        endDate.getFullYear(),
        endDate.getMonth() + 1,
        0
      );
    }
    const customValidation = this.customValidationCheck();
    if (customValidation) {
      if (!!this.formdata[this.formIdField]) {
        const paramObject = this.setParams();
        this.service
          .updateData(
            paramObject['endpoint'],
            this.formdata,
            this.formdata[this.formIdField]
          )
          .then((res: any) => {
            //console.log(res);
            if (res.status === 'ok') {
              this.storeInitialData = Object.assign({}, this.formdata);
              this.popUpClose.emit({
                type: 'update',
                data: this.screenData.modelData,
              });
            } else {
              notify(res.message, 'error', 5000);
            }
          });
      } else {
        //console.log('Adding Data....');
        if (this.formdata[this.formIdField] === '') {
          delete this.formdata[this.formIdField];
        }
        this.formdata['active'] = this.formdata['active'] ? true : false;
        this.formdata['processed'] = this.formdata['processed'] ? true : false;
        const paramObject = this.setInsertParams();
        this.service
          .addData(paramObject['endpoint'], paramObject['body'])
          .then((res: any) => {
            if (res.status === 'ok') {
              this.storeInitialData = Object.assign({}, this.formdata);
              let obj: any = res; //this.screenData.modelData;

              if (Object.prototype.hasOwnProperty.call(res, 'query')) {
                if (Object.prototype.hasOwnProperty.call(res.query, 'rules')) {
                  obj = res.query.rules[0];
                }
              }
              // //console.log("ee----",res.query.rules[0]);
              this.popUpClose.emit({ type: 'add', data: obj }); //this.screenData.modelData);
            } else {
              notify(res.message, 'error', 5000);
            }
          });
      }
    }
  }
  setInsertParams() {
    const Object = {};
    switch (this.screenData.modelData.model) {
      case 'Concept':
        var body = {};
        body['baseCode'] = this.parentData['baseCode'];
        body['rule'] = this.formdata;
        Object['body'] = body;
        Object['endpoint'] = 'rule';
        break;
      case 'Query':
        var body = {};
        body[this.formIdField] = this.parentData['_id'];
        body['rule'] = this.formdata;
        Object['body'] = body;
        Object['endpoint'] = 'queryrule';
        break;
      default:
        break;
    }
    return Object;
  }
  customValidationCheck() {
    var validationCheck = true;
    validationCheck = this.requiredFieldChecks();
    if (!validationCheck) return validationCheck;

    if (
      this.formdata['end_date'] &&
      !this.checkDate(this.formdata['start_date'], this.formdata['end_date'])
    ) {
      const errObj = {};
      errObj['field'] = 'DateRange';
      errObj['message'] = 'End Date should be greater than start date';
      this.addErrorList(errObj);
      validationCheck = false;
    } else {
      this.removeFromErrorList('DateRange');
    }
    if (!validationCheck) return validationCheck;

    for (const rule of this.allRulesGridData) {
      if (this.formdata[this.formIdField] === rule[this.formIdField]) break;
      if (this.formdata['end_date'] && rule['end_date']) {
        if (
          !(
            this.checkDate(this.formdata['end_date'], rule['start_date']) ||
            this.checkDate(rule['end_date'], this.formdata['start_date'])
          )
        ) {
          const errObj = {};
          errObj['field'] = 'Collision';
          errObj['message'] = 'Collision with other rule';
          this.addErrorList(errObj);
          validationCheck = false;
          break;
        }
      } else if (!rule['end_date'] && this.formdata['end_date']) {
        if (
          !(
            this.checkDate(this.formdata['end_date'], rule['start_date']) ||
            this.checkDate(rule['start_date'], this.formdata['start_date'])
          )
        ) {
          const errObj = {};
          errObj['field'] = 'Collision';
          errObj['message'] = 'Collision with other rule';
          this.addErrorList(errObj);
          validationCheck = false;
          break;
        }
      } else if (
        !this.formdata['end_date'] &&
        rule['end_date'] &&
        !this.checkDate(rule['end_date'], this.formdata['start_date'])
      ) {
        const errObj = {};
        errObj['field'] = 'Collision';
        errObj['message'] = 'Collision with other rule';
        this.addErrorList(errObj);
        validationCheck = false;
        break;
      } else if (
        !this.formdata['end_date'] &&
        !rule['end_date'] &&
        !this.checkDate(rule['start_date'], this.formdata['start_date'])
      ) {
        const errObj = {};
        errObj['field'] = 'Collision';
        errObj['message'] = 'Collision with other rule';
        this.addErrorList(errObj);
        validationCheck = false;
        break;
      } else {
        this.removeFromErrorList('Collision');
      }
    }
    return validationCheck;
  }
  requiredFieldChecks() {
    let validationCheck = true;
    for (const field of this.screen) {
      !!field.validatorRules &&
        field.validatorRules.forEach((element) => {
          switch (element.type) {
            case 'required': {
              //console.log('Here>>>>>');
              if (!this.formdata[field.dataField]) {
                const errObj = {};
                errObj['field'] = field.dataField;
                errObj['message'] = 'CONCEPT_RULES.REQSTARTDATE';
                this.addErrorList(errObj);
                //console.log('>>>>>>>>', this.customValidationErrors);
                validationCheck = false;
              } else {
                this.removeFromErrorList(field.dataField);
              }
              //statements;
              break;
            }
            default: {
              //statements;
              break;
            }
          }
        });
    }
    return validationCheck;
  }
  checkDate(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    if (startDate < endDate) {
      return true;
    } else {
      return false;
    }
  }
  /*
   *Added error to list
   */
  addErrorList(inValidObj) {
    /*
     *Same Error exist or not
     */
    if (
      !this.customValidationErrors.some(
        (error) => error.field === inValidObj['field']
      )
    ) {
      this.customValidationErrors.push(inValidObj);
    }
  }

  /*
   *Removing Error from list
   */
  removeFromErrorList(field) {
    if (this.customValidationErrors.some((error) => error.field === field)) {
      this.customValidationErrors.splice(
        this.customValidationErrors.findIndex((item) => item.field === field),
        1
      );
    }
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  syncTreeViewSelection(e) {
    //  //console.log("OPAAA", e)
    var component =
      (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.formdata['process_type']) {
      component.unselectAll();
    }

    if (this.formdata['process_type']) {
      this.formdata['process_type'].forEach(
        function (value) {
          component.selectItem(value);
        }.bind(this)
      );
    }
  }

  treeView_itemSelectionChanged(e) {
    //this.treeBoxValue = e.component._dataAdapter._selectedNodesKeys;
    this.formdata['process_type'] = e.component._dataAdapter._selectedNodesKeys;
  }

  syncTreeViewSelection1(e, name) {
    //  //console.log("OPAAA", e)
    var component =
      (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    if (!this.formdata[name]) {
      component.unselectAll();
    }
    if (this.formdata[name]) {
      //console.log(name, this.formdata[name]);

      component.selectItem(this.formdata[name]);
      /*this.formdata[name].forEach(
				function (value) {
					component.selectItem(value);
				}.bind(this)
			);*/
    }
  }

  treeView_itemSelectionChanged1(e, name) {
    //this.treeBoxValue = e.component._dataAdapter._selectedNodesKeys;

    this.formdata[name] = e.component._dataAdapter._selectedNodesKeys[0];
  }
}
