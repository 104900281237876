import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { DynamicAsideMenuConfig } from "../../configs/dynamic-aside-menu.config";
import axios from 'axios';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { delay } from 'rxjs/operators';

const emptyMenuConfig = {
  items: [],
};

@Injectable({
  providedIn: 'root',
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);

  menuConfig$: Observable<any>;

  constructor(private http: HttpClient) {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localStorage
  // Default => from DynamicAsideMenuConfig
  private async loadMenu() {
    this.setMenu(await this.getMenus);
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }

  async getMenus() {
    const items: any = [];

    //const result = await axios.get(`${environment.apiUrl}/generatemenu/1`, {});
    const result = await this.http
      .get<any>(`${environment.apiUrl}/generatemenu/1`)
      .pipe(delay(10))
      .toPromise();

    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      const { data } = result;
      let section = '';
      await data.forEach((item) => {
        const addItem: any = {};
        if (section !== item.section) {
          section = item.section;

          items.push({ section: item.section });
        }
        addItem.title = item.title;
        addItem.translate = item.translate;
        addItem.root = true;
        addItem.icon = item.icon;
        addItem.page = item.page;
        items.push(addItem);
      });
    }
    const defaults: any = {
      header: {
        self: {},
        items,
      },
      aside: {
        self: {},
        items,
      },
    };

    return defaults;
  }
}
