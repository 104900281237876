import { Injectable, Injector } from '@angular/core';
import { AxiosService } from 'src/app/_metronic/core/services/axios.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class FaceSheetService {
  isLoggedIn = false;

  http: AxiosService;
  constructor(private injector: Injector) {
    this.http = this.injector.get<AxiosService>(AxiosService);
  }
 
//  

async getHistExpenseDetails(obj: any) {
  return await this.http
    .axiosHTTP()
    .post(`${environment.apiUrl}/getHistExpenseDetails`, obj);
}
  
  
}
