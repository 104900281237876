<h2 class="no-print">Folha de Rosto <button class="btn btn-light-primary font-weight-bold text-center no-print" (click)="print()">Imprimir</button></h2>
<mat-dialog-content #content2>
<div *ngFor="let m of dataSource; index as j" class="month-containers">
  <h2>{{m.month}}</h2>
  <div *ngFor="let faceSheetData of m.data; index as i" class="face-sheet">
    <table>
      <tr>
        <th>Colaborador</th>
        <td>{{ faceSheetData.name }}</td>
      </tr>
      <tr>
        <th>Colab Número</th>
        <td>{{ faceSheetData.code }}</td>
      </tr>
      <tr>
        <th>Empresa</th>
        <td>{{ faceSheetData.company }}</td>
      </tr>
      <tr>
        <th>Unidade</th>
        <td>{{ faceSheetData.workunit }}</td>
      </tr>
      <tr>
        <th>Responsável</th>
        <td>{{ faceSheetData.resp }}</td>
      </tr>
      <tr>
        <th>Resp Número.</th>
        <td>{{ faceSheetData.resp_code }}</td>
      </tr>
      <!--<tr>
        <th>Form Nº</th>
        <td>{{ faceSheetData.form_n }}</td>
      </tr> -->
      <tr *ngFor="let key of getObjectKeys(faceSheetData.general_data)">
        <td>{{getCaption(faceSheetData.fields, key)}}:</td>
        <td>{{faceSheetData.general_data[key]}}</td>
      </tr>
   
    </table>
    <div class="contab" *ngFor="let itemDetail of faceSheetData.details; index as k;">
      <h3>{{itemDetail.name}}</h3>
      <table>
        <tr>
          <th>Data</th>
          <th>Prod</th>
          <th>Desc</th>
          <th>Value</th>
        </tr>
        <tr *ngFor="let item of itemDetail.data; index as l;">
          <td>{{ item.date }}</td>
          <td>{{ item.prod }}</td>
          <td>{{ item.desc }}</td>
          <td>{{ item.value }}</td>
        </tr>
        <tr>
          <th colspan="3">Total:</th>
          <td>{{ getContabTotal(j,i,k, itemDetail.name) }}</td>
        </tr>
      </table>
    </div>
  
    <!--<div class="rh">
      <h3>RH</h3>
      <table>
        <tr>
          <th>Data</th>
          <th>Prod</th>
          <th>Desc</th>
          <th>Value</th>
        </tr>
        <tr *ngFor="let item of faceSheetData.rh">
          <td>{{ item.data }}</td>
          <td>{{ item.prod }}</td>
          <td>{{ item.desc }}</td>
          <td>{{ item.value }}</td>
        </tr>
        <tr>
          <th colspan="3">Total:</th>
          <td>{{ getRhTotal(j,i) }}</td>
        </tr>
      </table>
    </div> -->
    
  </div>
  
</div>
</mat-dialog-content>
<div class="d-flex flex-center pt-7">
  <a (click)="closeDialog()" class="btn btn-light-primary font-weight-bold text-center no-print"
    >Fechar</a>
</div>