<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <div class="card-label" [innerHTML]="title"></div>
    </div>
  </div>
  <div class="card-body">
    <!--begin: Wizard -->
    <div #wizard class="wizard wizard-4" (wizardEmit)="wizardEvent($event)" id="kt_wizard_v4"
      data-wizard-state="step-first">
      <!--begin: Form Wizard Nav -->
      <div class="wizard-nav">
        <div class="wizard-steps">
          <a class="wizard-step" *ngFor="let item of menu; let i = index" href="javascript:;"
            [attr.data-wizard-type]="item.type" [attr.data-wizard-state]="item.state">
            <div class="wizard-wrapper">
              <div class="wizard-number">{{i+1}}</div>
              <div class="wizard-label">
                <div class="wizard-title">{{item.title}}</div>
                <div class="wizard-desc">{{item.subtitle}}</div>
              </div>
            </div>
          </a>




        </div>
      </div>
      <!--end: Form Wizard Nav -->

      <div class="card card-custom card-shadowless rounded-top-0">
        <div class="card-body p-0">
          <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-12">
              <!--    <form class="form mt-0 mt-lg-10" id="kt_form">-->
              <ng-content></ng-content>
              <!--begin: Form Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <div class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev">
                    {{previous}}
                  </div>
                </div>
                <div>
                  <div (click)="onSubmit()" class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit">
                    {{submit}}
                  </div>
                  <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                    {{next}}
                  </div>
                </div>
              </div>
              <!--end: Form Actions -->
              <!--</form>-->
              <!--end: Form Wizard Form-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end: Wizard -->
  </div>
</div>