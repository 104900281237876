import iban from "iban";
//import axios from 'axios';
//import { environment } from "../../../environments/environment";
export function validatorIBAN(value, bank, banks) {
  /*
  const banks = [
    { id: 1, code: "0035", country: "PT50" },
    { id: 2, code: "0018", country: "PT50" },
    { id: 3, code: "0033", country: "PT50" },
    { id: 4, code: "0010", country: "PT50" },
    { id: 5, code: "0007", country: "PT50" },
    { id: 6, code: "0269", country: "PT50" },
    { id: 7, code: "0079", country: "PT50" },
    { id: 8, code: "0046", country: "PT50" },
    { id: 9, code: "0036", country: "PT50" },
    { id: 10, code: "0193", country: "PT50" },
    { id: 11, code: "0019", country: "PT50" },
    { id: 12, code: "0023", country: "PT50" },
  ]; */

  if (iban.isValid(value)) {
    const p1 = value.slice(0, 4);
    const p2 = value.slice(4, 8);
    const r = banks.filter(
      (e) => e.id === parseInt(bank) && e.code === p2 && e.country === p1
    );

    if (r.length > 0) return true;
    else return false;

    //	const data = {model: 'banks', conditions:[{id: bank, code: p2, country: p1}] };
    //	return false;
    /*const result = await axios.get(`${environment.apiUrl}/generic/1`, {
			params: data,
		});
		if (result.data.code === 200) {
		   return result.data.result.length > 0 ? true : false;
		} else return false;  */
  } else {
    return false;
  }
}
