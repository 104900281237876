<div class="card card-custom gutter-b">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{title}}</h3>
    </div>
  </div>
  <div class="card-body">
    <!--begin: Wizard -->
    <div #wizard class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first">
      <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Form Wizard Nav -->
        <div class="wizard-steps">

          <a class="wizard-step" *ngFor="let item of menu" href="javascript:;" [attr.data-wizard-type]="item.type"
            [attr.data-wizard-state]="item.state">
            <div class="wizard-wrapper">
              <div class="wizard-icon">
                <i _ngcontent-vcw-c121="" class="fa {{item.icon}} menu-icon svg-icon"></i>
              </div>
              <div class="wizard-label">
                <div class="wizard-title">{{item.title}}</div>
                <div class="wizard-desc">{{item.subtitle}}</div>
              </div>
            </div>
          </a>
        </div>
        <!--end: Form Wizard Nav -->
      </div>
      <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <!--begin: Wizard Form-->
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <!--begin: Form Wizard Form-->
            <form class="form" id="kt_form">
              <ng-content></ng-content>
              <!--begin: Form Wizard Step 1-->
              <!--<div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <ng-content></ng-content>
              </div>-->

              <!--<div class="pb-5" data-wizard-type="step-content">
                teste 2
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                teste 3
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                teste 4
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                teste 5
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                teste 6
              </div>-->

              <!--<div *ngFor="let item of menu" class="pb-5" data-wizard-type="step-content"
                [attr.data-wizard-state]="item.content_state">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{item.content_title}}
                </h4>
              </div>
              <ng-content></ng-content>-->



              <!--begin: Form Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <div class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev">
                    {{previous}}
                  </div>
                </div>
                <div>
                  <div (click)="onSubmit()" class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit">
                    {{submit}}
                  </div>
                  <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                    {{next}}
                  </div>
                </div>
              </div>
              <!--end: Form Actions -->
            </form>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </div>
    </div>
    <!--end: Wizard -->
  </div>
</div>