import {
  Input,
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewChecked,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { delay } from 'lodash';
import { GenericService } from '../../../core/services/generic.service';
import { Router } from '@angular/router';
// import { Tab, Longtab, Service } from './app.service';

@Component({
  selector: 'kt-scroll-tabs',
  templateUrl: './scroll-tabs.component.html',
  styleUrls: ['./scroll-tabs.component.scss'],
})
export class ScrollTabsComponent implements OnInit, OnChanges {
  @Input() tabs: Array<any> = [{ content: '1' }];

  @Input() grid: any = [];

  @Input() dataSource: any = [];

  @Output() tabEmit: EventEmitter<any> = new EventEmitter();

  @Output() gridEmit: EventEmitter<any> = new EventEmitter();

  tabContent: string;
  sortTab: any = [];

  constructor(private service: GenericService, private elem: ElementRef,  private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges() {
    //	//console.log("Scroll Tabs>>", this.tabs);
    const route = this.router.url.split('/');
    
    
    if (this.tabs) {
       this.resultTabEmit(this.tabs[0]); // uraquitan
       if (route.includes("people") ) {
         //console.log(route.includes("people"));
         this.sortTab = this.tabs.filter(tab => tab.show !== false);
      }
      else {
         this.sortTab = [...this.tabs];
      }
      this.sortTab.sort((a, b) => a.text.localeCompare(b.text));
    }
    
  }

  selectedIndex = '0';

  currentSection = 'section0';

  onSectionChange(sectionId: string) {
    console.clear();
    //console.log('MUDOUUUU', sectionId);
    this.currentSection = sectionId;
    const getId = sectionId.split('section')[1];
    this.selectedIndex = getId;
    /* var getId = sectionId.split("section")[1];
    const tabdetail = this.tabs.find((x) => x.id === +getId);
    this.resultTabEmit(tabdetail); */
  }
/*
  scrollTo(tabdetail) {
    ////console.log("MUDOUUU 222", tabdetail)
    // //console.log("datas", tabdetail);
    this.selectedIndex = tabdetail.id;
    this.currentSection = `section${tabdetail.id}`;
    //	document.querySelector("#section" + tabdetail.id).scrollIntoView();
    this.resultTabEmit({
      id: tabdetail.id,
      content: [],
      data: this.tabs[tabdetail.id],
    });
  }*/

scrollTo(tabdetail) {
  // Encontre o índice real do item `tabdetail` em `tabs` pelo `id`
  const index = this.tabs.findIndex(tab => tab.id === tabdetail.id);

  if (index !== -1) {
    // Atualize `selectedIndex` para refletir a seleção no `dx-tabs`
    this.selectedIndex = index.toString();
    this.currentSection = `section${tabdetail.id}`;

    // Opção de rolar até a seção (descomente se necessário)
    // const element = document.querySelector(`#section${tabdetail.id}`);
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth", block: "start" });
    // }

    // Emite o resultado atualizado com os dados corretos
    this.resultTabEmit({
      id: tabdetail.id,
      content: [],
      data: this.tabs[index], // Use o item correto de `tabs` para os dados
    });
  }
}


  resultTabEmit(tabdetail) {
    //console.log('mudouuuu 3333', tabdetail);
    // if(!tabdetail) return;
    this.tabEmit.emit({
      data: tabdetail.content,
      content: this.tabs[tabdetail.id],
      id: tabdetail.id,
    });
  }

  selectTab(e) {
    this.scrollTo({ id: e.itemData.id });
  }

  onContentReady(e) {
    const elements = this.elem.nativeElement.querySelectorAll('.dx-tab');
    let maxWidth = 0;
    for (let i = 0; i < elements.length; i++) {
      maxWidth =
        maxWidth > elements[i].clientWidth ? maxWidth : elements[i].clientWidth;
    }
    elements.forEach((element) => {
      element.style.setProperty('width', `${maxWidth}px`);
    });
  }
}
