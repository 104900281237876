// Angular
import { Injectable, Injector } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import { AuthGuard2 } from 'src/app/guard/auth-guard';
import { AxiosService } from 'src/app/_metronic/core/services/axios.service';
//import axiosHTTP from 'src/app/_metronic/shared/axios/axios';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AsideService {
  // Public properties
  onConfigUpdated$: Subject<any>;

  // Private properties
  private menuConfig: any = {};

  http: AxiosService;

  constructor(private injector: Injector, private auth: AuthGuard2) {
    this.http = this.injector.get<AxiosService>(AxiosService);
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Load menus and return the menu configuration
   */
  async getMenus() {
    const items = await this.fetchMenuData();
    this.menuConfig = this.generateMenuConfig(items);
    return this.menuConfig;
  }

  private async fetchMenuData(): Promise<any[]> {
    const items: any[] = [];
    const result = await this.http.axiosHTTP().get(`${environment.apiUrl}/generatemenu/1`);

    if (result?.status === 200) {
      const { data } = result;
      let section = '';
      let submodel = '';
      let submodel2 = '';
      let list: any[] = [];
      let list2: any[] = [];
      let icon = '';

      for (const item of data) {
        // Handle new submodel
        if (submodel !== item.submodel) {
          this.pushSubmodel(items, submodel, submodel2, icon, list, list2);
          list = [];
          list2 = [];
          icon = item.icon || '';
          submodel = item.submodel;
        }

        // Handle new submodel2
        if (submodel2 !== item.submodel2) {
          this.pushSubmodel2(items, submodel, submodel2, icon, list, list2);
          list2 = [];
          submodel2 = item.submodel2;
        }

        // Handle new section
        if (section !== item.section) {
          section = item.section;
          items.push({ section, collapsed: true });
        }

        // Process items based on submodel and submodel2 values
        const addItem = this.createMenuItem(item);
        this.addMenuItem(addItem, items, submodel, submodel2, list, list2);
      }

      // Push remaining items at the end
      this.pushSubmodel(items, submodel, submodel2, icon, list, list2);
    }

    return items;
  }
  /**
   * Generate the final menu configuration object
   */
  private generateMenuConfig(items: any[]): any {
    const ite = this.groupBySubmodels(items);
    return {
      header: { self: {}, items:ite },
      aside: { self: {}, items: ite },
    };
  }

  /**
   * Helper function to create a menu item object
   */
  private createMenuItem(item: any): any {
    return {
      title: item.title,
      translate: item.translate,
      root: true,
      icon: item.icon || '',
      page: item.page,
    };
  }

  /**
   * Helper function to add a menu item to the appropriate list
   */
  private addMenuItem(
    addItem: any,
    items: any[],
    submodel: string,
    submodel2: string,
    list: any[],
    list2: any[]
  ) {
    if (submodel === 'none') {
      items.push(addItem);
    } else if (submodel !== 'none' && submodel2 === 'none') {
      list.push(addItem);
    } else if (submodel !== 'none' && submodel2 !== 'none') {
      list2.push(addItem)
    }
  }

  /**
   * Helper function to push submodel data into items array.
   */
  private pushSubmodel(items: any[], submodel: string, submodel2: string, icon: string, list: any[], list2: any[]) {
   // console.log(submodel, icon, list, submodel2, list2)
    if (list.length > 0) {
      if (list2.length > 0) {
        
        items.push({ submodel, icon, list, submodel2, list2 });
      } else {
        
        items.push({ submodel, icon, list });
      }
    }
  }
groupBySubmodels(arr) {
    const result = [];

    arr.forEach(item => {
        // Se for uma seção, adiciona diretamente
        if (item.section) {
            result.push({ section: item.section, collapsed: item.collapsed });
        } else if (item.submodel) {
            // Tenta encontrar um grupo existente para o submodel
            let submodelGroup = result.find(group => group.submodel === item.submodel);

            // Se não encontrar, cria um novo grupo
            if (!submodelGroup) {
                submodelGroup = {
                    submodel: item.submodel,
                    icon: item.icon,
                    list: [],
                };
                result.push(submodelGroup);
            }

            // Se existe submodel2 e não é 'none'
            if (item.submodel2 && item.submodel2 !== 'none') {
                // Tenta encontrar uma definição existente para o submodel2
                let definitionGroup = submodelGroup.list.find(def => def.submodel2 === item.submodel2);
                
                // Se não encontrar, cria uma nova definição
                if (!definitionGroup) {
                    definitionGroup = {
                        submodel2: item.submodel2,
                        list2: [],
                    };
                    submodelGroup.list.push(definitionGroup);
                }

                // Adiciona os itens em list2 se houver
                if (item.list2 && item.list2.length > 0) {
                    definitionGroup.list2.push(...item.list2);
                }
            }

            // Adiciona itens em list se existirem
            if (item.list && item.list.length > 0) {
                submodelGroup.list.push(...item.list);
            }
        } else {
            // Para itens sem submodel, mas com título, podemos adicioná-los diretamente
            if (item.title) {
                result.push(item);
            }
        }
    });

    return result;
}





  /**
   * Helper function to push submodel2 data.
   */
  private pushSubmodel2(items: any[], submodel: string, submodel2: string, icon: string, list: any[], list2: any[]) {
    if (list2.length > 0) {
      const itemIndex = items.findIndex(item => item.submodel === submodel);
      if (itemIndex !== -1) {
        items[itemIndex].list2.push(...list2);
      } else {
        items.push({ submodel, submodel2, icon, list2 });
      }
      
    }
  }

  /**
   * Load config and update menu
   */
  async loadConfigs(config: any) {
    const items = await this.fetchMenuData();
    this.menuConfig = this.generateMenuConfig(items);
    this.onConfigUpdated$.next(this.menuConfig);
  }
}
