<div id="tab" class="kt-portlet">
  <div class="scrollTabs">
    <div class="tabRow horizontal-size">
      <div class="pl-0 pr-0 tablist">
        <dx-tabs
          [dataSource]="tabs"
          [scrollByContent]="true"
          [scrollingEnabled]="true"
          [showNavButtons]="true"
          [selectedIndex]="selectedIndex"
          (onItemClick)="selectTab($event)"
          (onContentReady)="onContentReady($event)"
        >
        </dx-tabs>
      </div>
      <div class="tabRow dropdown pl-0 pr-0">
        <dx-button
          class="dropdown-toggle moreButton"
          icon="chevrondown"
          id="moreTabsButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
        </dx-button>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="moreTabsButton"
        >
          <div
            class="dropdown-item"
            *ngFor="let tab of sortTab"
            (click)="scrollTo({ data: tab, id: tab.id })"
          >
            {{ tab.text }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div
      id="parentDiv"
      class="scrollspy"
      scrollSpy
      [spiedTags]="['DIV']"
      (sectionChange)="onSectionChange($event)"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
