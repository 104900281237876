import { ChangeDetectorRef, Injectable, Injector } from '@angular/core';
import { environment } from '../../../../environments/environment';
//import this.http.axiosHTTP() from '../../shared/axios/axios';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Subscribable } from 'rxjs';
import { Router } from '@angular/router';
import { disableDebugTools } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { AxiosService } from './axios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


const DateTimeRegex =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?$/;

@Injectable()
export class GenericService {
  // axiosS: any;
  http: any;
  constructor(private router: Router, private injector: Injector) {
    this.http = this.injector.get<AxiosService>(AxiosService);
  }

  async apiRoute(route: string) {
    const res = await this.http
    .axiosHTTP()
    .get(`${environment.apiUrl}/${route}`, {
      
    });
    if( res.status===200) {
      return res.data.result;
    }
    return [];
  }
  async getData(data: any) {
    let cond: any = JSON.stringify(data);
    
    cond = cond.split(':now').join(this.formatDate(new Date()));
    
    cond = cond.split('!id_companies').join(localStorage.getItem("id_companies") || '0');


    //primeiro dia do mes
    cond = cond.split(':firstdayofmonth').join(this.firstDayOfMonth());
    cond = JSON.parse(cond);
    if (cond.order >= 0) delete cond.order;
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/generic/1`, {
        params: cond,
      });
    /*const obj = Object.keys(data);
    let params: HttpParams = new HttpParams();
    for(let i=0; i < obj.length; i++) {
      
       params = params.set(obj[i],data[obj[i]]);
    }
     //console.log("VEIO AQUIII antes", params);*/
    //  let headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    /*  const result =await this.http
      .get<any>(`${environment.apiUrl}/generic/1`, {
      params:{ rd: JSON.stringify(data)},
    //  headers
    })
      .pipe(delay(10))
      .toPromise();
    //console.log("VEIO AQUIII", data);*/
    
    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      return result.data;
    }
    return [];
  }

  async getKeycloakData(data: any) {
    // //console.log('DATAAAA', data);
    let cond: any = JSON.stringify(data);
    cond = cond.split(':now').join(this.formatDate(new Date()));
    //primeiro dia do mes
    cond = cond.split(':firstdayofmonth').join(this.firstDayOfMonth());
    cond = JSON.parse(cond);


    if (cond.order >= 0) delete cond.order;
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/keycloak/1`, {
        params: cond,
      });
    /*const obj = Object.keys(data);
    let params: HttpParams = new HttpParams();
    for(let i=0; i < obj.length; i++) {
      
       params = params.set(obj[i],data[obj[i]]);
    }
     //console.log("VEIO AQUIII antes", params);*/
    //  let headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    /*  const result =await this.http
      .get<any>(`${environment.apiUrl}/generic/1`, {
      params:{ rd: JSON.stringify(data)},
    //  headers
    })
      .pipe(delay(10))
      .toPromise();
    //console.log("VEIO AQUIII", data);*/
    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      return result.data;
    }
    return [];
  }

  getDataPromise(data: any): Promise<any> {
    // //console.log('DATAAAA', data);
    let cond: any = JSON.stringify(data);
    cond = cond.split(':now').join(this.formatDate(new Date()));
    //primeiro dia do mes
    cond = cond.split(':firstdayofmonth').join(this.firstDayOfMonth());
    cond = JSON.parse(cond);

    if (cond.order >= 0) delete cond.order;

    return new Promise<any>(async (doneFilter, failFilter) => {
      const x = await this.http
        .axiosHTTP()
        .get(`${environment.apiUrl}/generic/1`, {
          params: cond,
        });
      //console.log('xxx', x.data.result);
      if (x.status === 200) {
        doneFilter(x.data.result);
      } else failFilter([]);
    });
  }

  async checkPermissionScreen(regex, replace) {
    // const regex = /^\/base\/[a-z A-Z _]+/;
    const found = this.router.url.match(regex);
    ////console.log('REGEX', found);
    let screenID = '';
    if (found) {
      screenID = found[0].replace(replace, '');
    }

    //CHECK PERMISSION
    const allow: any = await this.allowRoles({ screenID });

    if (!allow.access.allow) {
      Swal.fire({
        title: 'Permissão de ecrã',
        text: 'Você não possui permissão para acessar esta ecrã',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn font-weight-bold btn-light',
        },
      }).then(function () {
        //	KTUtil.scrollTop();
      });
      this.router.navigateByUrl('dashboard');
    }
    if (allow.access.allow && allow.id === 0) {
      Swal.fire({
        title: 'Permissão de tela',
        text: 'Por favor, atualize primeiro seus dados',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn font-weight-bold btn-light',
        },
      }).then(function () {
        //	KTUtil.scrollTop();
      });
      this.router.navigateByUrl('profile');
    }
    return { allow }; //{ allow: allow.access };

    //END CHECK PERMISSION
  }

  allowRoles(data: any) {
    return new Promise(async (solve, error) => {
      const access = await this.http.allowRoles(data);

      ////console.log('acesso', access);
      solve(access);
    });
  }

  async getDataResult(data: any) {
    ////console.log("DATAAAA", data);
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/generic/1`, {
        params: data,
      });

    if (result.status === 200) {
      // //console.log('RESULT.DATA======================', result.data.result);
      return result.data.result;
    }
    return [];
  }

  async getDataPerRoute(route: string, data: any) {
    ////console.log("DATAAAA", data);
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/${route}`, {
        params: data,
      });

    if (result.status === 200) {
      return result.data;
    }
    return [];
  }

  async uploadBase64(data: any) {
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/upload`, data)
      .catch((err) => err.response);
    //		//console.log("result", result);
    if (result.status === 200) {
      return result.data;
    }
    return result.data;
  }

  async addData(data: any, id: number = 0) {
    this.dateDataChange(data.data);
    //	//console.log("chegou add");
   // console.log("SERVICE", data);
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/generic`, data);
    //		//console.log("result", result);
    if (result.status === 200) {
      return result.data;
    }
    return result.data;

    return [];
  }

  async updateData(data: any, id: number) {
    this.dateDataChange(data.data);
    // //console.log('SERVICE', data);
    if (data.model === 'Concept') data.key = data.data._id;
    const result = await this.http
      .axiosHTTP()
      .put(`${environment.apiUrl}/generic/${id}`, data)
      .catch((err) => err.response);
    Promise.all([result]);
    if (result.status === 200) {
      return result.data;
    }
    return result.data;
  }

 async setSession(obj:any) {
    return await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/setSession`, obj);
      
  }


  async deleteKeycloak(model: any, id: any) {
    ////console.log('Check>>>', JSON.stringify(data));
    let result;

    result = await this.http
      .axiosHTTP()
      .delete(`${environment.apiUrl}/keycloak/${id}`, { params: { model } })
      .catch((err) => err.response);

    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      return result.data;
    }
    return result.data;

    return [];
  }

  async deleteData(data: any, id: any) {
    //console.log('Check>>>', JSON.stringify(data));
    if (data.model === 'Concept') data.key = data.data._id;
    const result = await this.http
      .axiosHTTP()
      .delete(`${environment.apiUrl}/generic/${id}`, {
        params: data,
      })
      .catch((err) => err.response);

    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      return result.data;
    }
    return result.data;

    return [];
  }

  async getScreen(data: string) {
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/pageConfig/${data}`);

    if (result && result.status === 200) {
      return result;
    }
    return [];
  }

  getDistricts() {
    alert(1);
  }

  getDataSource(data) {
    const store = new CustomStore({
      load: () => this.getData(data),
      key: 'id',
    });

    return new DataSource({
      store,
    });
  }

  getDataSourceAndGrid(model: string, params: any) {
    return new Promise(async (solve, error) => {
      let dataSource: DataSource;
      let grid: any[] = [];
      // //console.log("paerms", params);
      if (params.sourceType && params.sourceType === 'subDocument') {
        const screen: any = params;
        dataSource = await this.getData({
          link: params.link,
          element: params.element,
          key: params.key,
          model: params.model,
          fields: params.fields,
          conditions: params.conditions,
          sourceType: params.sourceType,
        });

        // //console.log("datas", dataSource);
        // //console.log("OLHA SCREEN11", JSON.stringify(dataSource));
        if (params.screen) {
          screen.screen = params.screen;
        }
        const columns = screen.screen;
        columns.map((e: any) => {
          if (e.lookup) {
            if (!Array.isArray(e.lookup.dataSource.result)) {
              this.getData(e.lookup.dataSource).then((data) => {
                
                e.lookup.dataSource = data;
              });
            } else {
              e.lookup.dataSource = e.lookup.dataSource;
            }
          }
        });

        grid = columns;
        solve({ dataSource, grid, screen });
      } else {
        const r: any = await this.getScreen(model);
        // //console.log(r);
        if (r.status === 200) {
      //console.log("params", params);
      //console.log(r);

          let screen: any = r.data;
          
          if(screen.conditions) {
              let aux1 = JSON.stringify(screen.conditions);
              screen.conditions = JSON.parse(aux1.split('!id_companies').join(localStorage.getItem("id_companies") || '0'));
          }
          //    //console.log("MARQUES DE POMBAL", JSON.stringify(r));
          ////console.log("datasource", await this.service.getData(DATA));

          if(screen.query) {
              let aux = screen.query;
              screen.query = aux.split('!id_companies').join(localStorage.getItem("id_companies") || '0');

             // console.log("query",screen.query);
          } 
          else {
            let abc = screen.screen;
            let aux = JSON.stringify(abc);
            screen.screen = JSON.parse(aux.split('!id_companies').join(localStorage.getItem("id_companies")));
//            console.log("aqui", screen.screen)
          }
          if (!screen.keycloak) {
            if(!screen.apiRoute) {
            dataSource = await this.getData({
              model: screen.model,
              fields: screen.fields,
              query: screen.query,
              conditions: params.conditions || screen.conditions,
              order: params.order,
              sourceType: screen.sourceType,
            });
          }
          else {
             dataSource = await this.apiRoute(screen.apiRoute);
          }
          } else {
            dataSource = await this.getKeycloakData({
              model: screen.model,
              fields: screen.fields,
              conditions: params.conditions,
              order: params.order,
              sourceType: screen.sourceType,
            });
          }
          ////console.log("DATAAAAAA--->>>",dataSource)
          // //console.log("datas", dataSource);
          // //console.log("OLHA SCREEN11", JSON.stringify(dataSource));
          if (params.screen) {
            //			//console.log("OLHA SCREEN", params.screen);
            screen.screen = params.screen;
          }
          const columns = screen.screen;
          // columns.map(async (e: any) => {
          for (const e of columns) {
            if (e.lookup) {
              //console.log(e.lookup.displayExpr)
              if( Array.isArray(e.lookup.dataSource)) {
               
                 e.lookup.dataSource = e.lookup.dataSource;
              }
              else if (!Array.isArray(e.lookup.dataSource.result)) {
                if (e.dependencies) {
                  e.dependencies.forEach((element) => {
                    const gridItem = columns.find(
                      (x) => x.dataField === element
                    );
                    if (!gridItem.dependentColumn) {
                      gridItem.dependentColumn = [];
                    }
                    gridItem.dependentColumn.push(e.dataField);
                  });
                }
                let lookupParams;
                if (e.lookup.dataSource.listData) {
                  lookupParams = {
                    listData: e.lookup.dataSource.listData,
                  };
                } else {
                  lookupParams = e.lookup.dataSource;
                }
                // //console.log('LOOKUP', lookupParams);
                /* this.getData(lookupParams).then((data) => {
								e.lookup.dataSource.result = data.result;
							}); */
                //   //console.log("LOOKUP", dataSource, lookupParams);
                const data = await this.getData(lookupParams);
                //console.log("DATA LOOKUP",  JSON.stringify(data));
               
                let result = data.result;
//console.log("DATA LOOKUP", e.lookup.displayExpr);
                if (e.lookup.displayExpr.includes("#")) {
                  
                  // Separa os campos antes e depois do #
                  const [idItem, firstField, secondField] = e.lookup.displayExpr.split("#");

                  // Gera uma nova lista com os campos id_concept e a junção de base_code e name
                  result = result.map(item => ({
                    id: item[idItem],
                    name: `${item[firstField]} - ${item[secondField]}`
                    //[e.lookup.displayExpr]: `${item[firstField]} - ${item[secondField]}`
                  }));
               //   console.log("DATA LOOKUP",  result);
                }
                 e.lookup.displayExpr = "name";
                 e.lookup.dataSource.result = result; //data.result;  
                
                
              //  console.log(e.lookup)
              } else {
                
                //	//console.log("JAJAJAA", e.lookup.dataSource);
                /* if (
									!Array.isArray(e.lookup.dataSource.result)
								) {
									e.lookup.dataSource.result =
										e.lookup.dataSource;
								} */
              }
            }
            else {
              if(e.editorType === "dxSelectBox") {
                  e.editorType =""
              }
              
            }
            // });
          }
          grid = columns;
          //console.log('GRIDDD', grid);
          solve({ dataSource, grid, screen });
        } else {
          error({ message: 'NO DATA' });
        }
      }
    });
  }

  async getLookupdata(params) {
    let cond: any = JSON.stringify(params);
    cond = cond.split(':now').join(this.formatDate(new Date()));
    //primeiro dia do mes
    cond = cond.split(':firstdayofmonth').join(this.firstDayOfMonth());
    cond = JSON.parse(cond);

    delete cond.result;
    const result = await this.getData(cond).catch((err) => err.response);
    Promise.all([result]);
    if (result.status === 400) {
      return [];
    }
    return result.result;
  }

  async getDataFromEndpoint(endpoint: any) {
    // //console.log("Endpoint>>",   endpoint.length, "B" +  endpoint + "B");

    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}${endpoint}`);
    Promise.all([result]);
    // //console.log('result>>', result);
    if (result.status === 200) {
      return result.data;
    }
    return [];
  }

  dateDataChange(data: any) {
    for (const [key, value] of Object.entries(data)) {
      if (
        !!value &&
        (DateTimeRegex.test(value.toString()) || value instanceof Date)
      ) {
        // data[key] = new Date(value.toString()).toUTCString();
        data[key] = this.formatDate(value);
      }
    }
  }

  navigateToRoute(name) {
  this.router.navigate([name]).then(() => {
    this.router.navigate([this.router.url]); // Força a atualização
});

  }

  formatDate(date) {
    if (!(DateTimeRegex.test(date.toString()) || date instanceof Date)) {
      return date;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  formatDateTime(date) {
    
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    const hour = d.getHours();
    const min = d.getMinutes();
    const sec = d.getSeconds();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-') + " " +[hour, min, sec].join(":");
  }

  async getCustom(data: any) {
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/custom/1`, {
        params: data,
      });
    return result;
  }

  async execCustomSQL(data: any) {
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/execCustomSQL/`, {
        data,
      });
    return result;
  }
  async execSQL(data: any) {
    const result = await this.http
      .axiosHTTP()
      .post(`${environment.apiUrl}/execSQL/`, {
        data,
      });
    return result;
  }
  firstDayOfMonth() {
    // Get the current date
    const date = new Date();
    
    // Get the year and month components of the date
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    
    // Combine the components into a string in the desired format
    return `${yyyy}-${mm}-01`;
  }
}
